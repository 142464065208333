import React from 'react'
import PropTypes from 'prop-types'
import ServerStatus from '../ServerStatus/ServerStatus.container'
import Tab from '../../components/Navigation/Tab'
import Tabs from '../../components/Navigation/Tabs'
import { Grid } from './FuelLevelTopbar.styles'

/**
 * The FuelLevelTopbar's container.
 */
const FuelLevelTopbar = props => {
  const { currentTab, onChangeTab } = props

  return (
    <Grid container direction="row" justify="space-between" alignItems="flex-end">
      <Grid item xs={6} md={8}>
        <Tabs value={currentTab} onChange={onChangeTab} aria-label="Fuel levels tabs" variant="scrollable">
          <Tab label="Estanques CAEX" value="CAEX" />
          <Tab label="Últimos abastecimientos" value="orpak" />
          <Tab label="Boxplots consumos" value="boxplots" />
          <Tab label="Gráfico de control" value="graphs" />
          <Tab label="Nivel de estanque" value="fuelLevelBoxplot" />
          <Tab label="Gráficos presentación" value="gerencia" />
        </Tabs>
      </Grid>
      <Grid item xs={6} md={4}>
        <ServerStatus />
      </Grid>
    </Grid>
  )
}

FuelLevelTopbar.propTypes = {
  currentTab: PropTypes.string,
  onChangeTab: PropTypes.func
}

export default FuelLevelTopbar
