import { combineReducers } from 'redux'
import authenticationReducer from '../../containers/Authentication/Authentication.reducer'
import countdownReducer from '../../containers/Countdown/Countdown.reducer'
import errorsReducer from '../../store/reducers/errorsReducer'
import fuelLevelReducer from '../../containers/FuelLevel/FuelLevel.reducer'
import hardBrakingReducer from '../../containers/HardBraking/HardBraking.reducer'
import helpReducer from '../../containers/Help/Help.reducer'
import newAnomalyReducer from '../../containers/NewAnomaly/NewAnomaly.reducer'
import idleRpmReducer from '../../containers/IdleRpm/IdleRpm.reducer'
import managementMenuReducer from '../../containers/ManagementMenu/ManagementMenu.reducer'
import reinforcementReducer from '../../containers/Reinforcement/Reinforcement.reducer'
import reloadReducer from '../../store/reducers/screenReducer'
import rpmDownloadReducer from '../../containers/RpmDownload/RpmDownload.reducer'
import newAnomalyTopbarReducer from '../../containers/NewAnomalyTopbar/NewAnomalyTopbar.reducer'
import newAnomalyFilterReducer from '../../containers/NewAnomalyFilter/NewAnomalyFilter.reducer'
import quickSightReducer from '../../containers/QuickSight/QuickSight.reducer'
import dispatchOrpakReducer from '../../containers/dispatchOrpak/dispatchOrpak.reducer'
import keplerGlReducer from 'kepler.gl/reducers'
import mapboxReducer from '../../containers/VisualizadorEspacial/VisualizadorEspacial.reducer'
import mapboxHistoricReducer from '../../containers/VisualizadorEspacialHistorico/VisualizadorEspacialHistorico.reducer'
import mapboxFrenadosReducer from '../../containers/VisualizadorEspacialFrenados/VisualizadorEspacialFrenados.reducer'
import predictionsReducer from '../../containers/Predictions/Predictions.reducer'
import anomalyTiresReducer from '../../containers/Tires/Tires.reducer'
import fuelLevelGraphReducer from '../../containers/FuelLevelBoxplots/BoxPlotGraph.reducer'
import fuelManagementReducer from '../../containers/FuelManagement/FuelManagement.reducer'
import gerenciaReducer from '../../containers/GerenciaGraphs/Gerencia.reducer'
import generalDataReducer from '../../containers/DiningDriver/DiningDriver.reducer'
import { LOCALE_CODES } from 'kepler.gl/localization'

const customizedKeplerGlReducer = keplerGlReducer.initialState({
  uiState: {
    // use Spanish locale
    locale: LOCALE_CODES.es,
    mapControls: {
      mapLegend: {
        show: true,
        active: true
      }
    }
  }
})

export default combineReducers({
  newAnomaly: newAnomalyReducer,
  newAnomalyTopbar: newAnomalyTopbarReducer,
  newAnomalyFilter: newAnomalyFilterReducer,
  quickSight: quickSightReducer,
  auth: authenticationReducer,
  countdown: countdownReducer,
  error: errorsReducer,
  fuelLevel: fuelLevelReducer,
  hardBraking: hardBrakingReducer,
  help: helpReducer,
  idleRpm: idleRpmReducer,
  managementMenu: managementMenuReducer,
  reinforcement: reinforcementReducer,
  rpmDownload: rpmDownloadReducer,
  dispatchOrpak: dispatchOrpakReducer,
  screen: reloadReducer,
  keplerGl: customizedKeplerGlReducer,
  mapbox: mapboxReducer,
  mapboxHistoric: mapboxHistoricReducer,
  mapboxHardBraking: mapboxFrenadosReducer,
  predictionsGraph: predictionsReducer,
  anomalyTires: anomalyTiresReducer,
  fuelLevelGraphs: fuelLevelGraphReducer,
  fuelManagement: fuelManagementReducer,
  gerencia: gerenciaReducer,
  dining: generalDataReducer
})
