import React, { useState } from 'react'
import routes from '../config/settings/routes'
import { useTabs } from '../components/Navigation/Tab.hooks'
import FuelLevelContent from '../containers/FuelLevel/FuelLevelContent.container'
import BoxplotContent from '../containers/FuelLevelBoxplots/BoxPlotGraph.container'
import FuelLevelGraphs from '../containers/FuelLevelBoxplots/Graph.container'
import FuelLevelBoxPlotContent from '../containers/FuelLevelBoxplots/FuelLevelBoxPlot.container'
import FuelLevelTopbar from '../containers/FuelLevel/FuelLevelTopbar.container'
import FuelLevelOrpak from '../containers/FuelLevel/FuelLevelOrpak.container'
import ManagementSectionContainer from '../containers/ManagementMenu/ManagementMenu.container'
import NavbarContainer from '../containers/Navbar/Navbar.container'
import BrandLayout from '../components/Layouts/BrandLayout'
import Heading from '../components/Layouts/Heading'
import ProtectByFeatures from '../components/Security/ProtectByFeatures'
import ProtectBySession from '../components/Security/ProtectBySession'
import ServiceUnavailablePage from './ServiceUnavailable'
import { Container } from '@material-ui/core'
import GerenciaContainer from '../containers/GerenciaGraphs/Gerencia.container'

/**
 * The FuelLevel' page.
 */
const FuelLevel = () => {
  const title = 'Nivel de Combustible'
  const [fullScreen, setFullScreen] = useState(false)
  const { value: currentTab, onChange: onChangeTab } = useTabs({ initialValue: 'CAEX' })

  const renderTopbar = () => <FuelLevelTopbar currentTab={currentTab} onChangeTab={onChangeTab} />

  return (
    <ProtectBySession rule={(hasSession, signedIn) => !!(hasSession && signedIn)}>
      <ProtectByFeatures
        rule={features => features['com.cosmos/fuel.level']}
        fallback={() => <ServiceUnavailablePage />}
      >
        <BrandLayout
          navbar={<NavbarContainer />}
          header={fullScreen ? null : <Heading title={title} topbar={renderTopbar} />}
          sidebar={<ManagementSectionContainer current={routes.fuelLevel} />}
        >
          <Container maxWidth={false} style={{ background: 'white', padding: 10, display: 'flex' }}>
            {currentTab === 'CAEX' && (
              <FuelLevelContent title={currentTab} fullScreen={fullScreen} setFullScreen={setFullScreen} />
            )}
            {currentTab === 'aljibes' && <FuelLevelContent title={currentTab} />}
          </Container>
          <Container maxWidth={false} style={{ background: 'white', padding: 10 }}>
            {currentTab === 'orpak' && <FuelLevelOrpak />}
            {currentTab === 'boxplots' && <BoxplotContent />}
            {currentTab === 'graphs' && <FuelLevelGraphs />}
            {currentTab === 'fuelLevelBoxplot' && <FuelLevelBoxPlotContent />}
            {currentTab === 'gerencia' && <GerenciaContainer />}
          </Container>
        </BrandLayout>
      </ProtectByFeatures>
    </ProtectBySession>
  )
}

export default FuelLevel
